import Vue from "vue";
import _ from "lodash";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);
let viewsRoutes = [];
const routerContext = require.context("./module", false, /\.js$/);
routerContext.keys().forEach((route) => {
  const routerModule = routerContext(route);
  viewsRoutes = [...viewsRoutes, ...(routerModule.default || routerModule)];
});
console.log(viewsRoutes, 22);

/* 用于判断路由是否存在名称重复 | 或路径重复 */
function vaildRouteRepeat(routes, str) {
  console.log("触发了");
  let obj = {};
  routes.forEach((route) => {
    if (obj[route[str]]) {
      obj[route[str]]++;
      console.log(route[str], obj[route[str]]);
    } else {
      obj[route[str]] = 1;
    }
  });
  let result = [];
  Object.keys(obj).forEach((key) => {
    if (obj[key] > 1) {
      console.log(key);
      result.push(key);
    }
  });
  if (result.length > 0) {
    console.log(result, "result---");
    alert(`检测到重复的${str}：${result.join("、")}`);
  }
}
vaildRouteRepeat(viewsRoutes, "name"); // name重复
vaildRouteRepeat(viewsRoutes, "path"); // path重复
/* end */

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  ...viewsRoutes,
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;
//push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};
//replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch((err) => err);
};

export default router;
