const routes = [
  //  综合执法监管平台
  {
    path: "/lawEnforcement/login",
    name: "lawEnforcement-login",
    meta: {
      title: "",
    },
    component: (resolve) => require(["@/views/lawEnforcement/login"], resolve),
  },
  //事件上报
  {
    path: "/lawEnforcement/lawEnforcement-escalation",
    name: "lawEnforcement-escalation",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/lawEnforcement/lawEnforcement-escalation/index.vue",
      ], resolve),
  },
  {
    path: "/lawEnforcement/escalationForm",
    name: "escalationForm",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/lawEnforcement/lawEnforcement-escalation/escalationForm.vue",
      ], resolve),
  },
  {
    path: "/lawEnforcement/myEscalation",
    name: "myEscalation",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/lawEnforcement/lawEnforcement-escalation/myEscalation.vue",
      ], resolve),
  },
  {
    path: "/lawEnforcement/escalationDetail",
    name: "escalationDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/lawEnforcement/lawEnforcement-escalation/escalationDetail.vue",
      ], resolve),
  },
  //处理
  {
    path: "/lawEnforcement/lawEnforcement-handle",
    name: "lawEnforcement-handle",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/lawEnforcement/lawEnforcement-handle/index.vue",
      ], resolve),
  },
  {
    path: "/lawEnforcement/handleList",
    name: "lawEnforcement-handleList",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/lawEnforcement/lawEnforcement-handle/handleList.vue",
      ], resolve),
  },
  {
    path: "/lawEnforcement/handleDetail",
    name: "lawEnforcement-handleDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/lawEnforcement/lawEnforcement-handle/handleDetail.vue",
      ], resolve),
  },
  //管理
  {
    path: "/lawEnforcement/lawEnforcement-manager",
    name: "lawEnforcement-manager",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/lawEnforcement/lawEnforcement-manager/index.vue",
      ], resolve),
  },
  {
    path: "/lawEnforcement/managerList",
    name: "lawEnforcement-managerList",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/lawEnforcement/lawEnforcement-manager/managerList.vue",
      ], resolve),
  },
  {
    path: "/lawEnforcement/managerListElse",
    name: "lawEnforcement-managerListElse",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/lawEnforcement/lawEnforcement-manager/managerListElse.vue",
      ], resolve),
  },
  {
    path: "/lawEnforcement/managerDetail",
    name: "lawEnforcement-managerDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/lawEnforcement/lawEnforcement-manager/managerDetail.vue",
      ], resolve),
  },
];

export default routes;
